import { getTheme } from '../../themes';
import type { AppProps } from 'next/app';
import { I18nProvider } from 'next-localization';
import { ChakraProvider } from '@chakra-ui/react';
import { SitecorePageProps } from 'lib/page-props';
import '@fontsource/saira-stencil-one/400.css';
import '@fontsource/anton';
import '@fontsource/inter';
import 'assets/app.scss';

function App({ Component, pageProps }: AppProps<SitecorePageProps>): JSX.Element {
  const { dictionary, ...rest } = pageProps;
  const theme = getTheme(process.env.NEXT_PUBLIC_HOSTEL_THEME);

  return (
    <>
      {/*Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app. */}
      {/*Note Next.js does not (currently) provide anything for translation, only i18n routing. If your app is not*/}
      {/*multilingual, next-localization and references to it can be removed.*/}
      <I18nProvider lngDict={dictionary} locale={pageProps.locale}>
        <ChakraProvider theme={theme.config}>
          <Component {...rest} />
        </ChakraProvider>
      </I18nProvider>
    </>
  );
}

export default App;
