var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"20240826-hotfix-18550"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
Sentry.init({
  dsn: SENTRY_DSN || 'https://eddbe6a802874d8e92372876d24a37e1@o4504750389002240.ingest.sentry.io/4504750389133312',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.02,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.2,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.2,
  release: process.env.SENTRY_RELEASE,
  environment: process.env.SENTRY_ENVIRONMENT,
  dist: process.env.SENTRY_DIST,
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    })],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === 'xhr') {
      // hint.xhr is a whole XHR object that you can use to modify breadcrumb
      // breadcrumb.data = (hint.xhr as XMLHttpRequest).response;
      const data = {
        requestBody: hint.xhr.__sentry_xhr__.body,
        response: hint.xhr.response,
        responseUrl: hint.xhr.responseURL,
      };
      return { ...breadcrumb, data };
    }

    return breadcrumb;
  },
  beforeSend: (event, hint) => {
    return !!navigator.userAgent.match(/bot/i) || !!navigator.userAgent.match(/scanner/i) ? null : event;
  },
  ignoreErrors: [
    'Could not load "overlay".',
    'Network Error',
    'TypeError: Load failed',
    'Could not load "util".',
    'ChunkLoadError',
    'TypeError: undefined is not an object',
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // google
    /maps\.google\.com/i,
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: process.env.NODE_ENV !== 'development',
});
